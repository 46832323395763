import React, { useState } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from "dayjs/plugin/utc";

dayjs.extend(timezone);
dayjs.extend(utc);

export default function Voted(props: { state?: string; message?: string; timestamp?: number } & React.ImgHTMLAttributes<HTMLImageElement>) {
    const [active, setActive] = useState<boolean>(true);
    console.log(props);
    if (!props.state) {
        return null;
    }
    let message = '';
    if (props.state === 'complete') {
        message = 'WEB投票が完了しました';
    } else if (props.state === 'failed') {
        message = 'WEB投票に失敗しました';
    }
    if (!message) {
        return null;
    }
    let votedAt = '';
    if (props.timestamp && props.timestamp > 0){
      votedAt = `最終受付: ${dayjs.unix(props.timestamp).tz("Asia/Tokyo").format("MM月DD日 HH:mm")}`;
    }
    return (
        <div
            className={classNames('modal voted', {
                'is-active': active,
            })}
        >
            <div
                className="modal-background has-background-gradient"
                onClick={function() {
                    return close();
                }}
            />
            <div className="modal-card">
                <header className="modal-card-head pt-2 pl-3 pr-3" />
                <section className="modal-card-body has-text-centered">
                    <figure className="image is-128x128 mb-6">
                        <img className="is-rounded" src={props.src} alt={props.alt} />
                        {props.state === 'complete' && (
                            <span className="icon is-large ">
                <i className="fas fa-2x fa-check-circle" />
              </span>
                        )}
                    </figure>
                    <h1 className="title has-text-weight-normal is-size-5 mb-3">{message}</h1>
                    <p
                        className={classNames('tag is-size-7 has-text-weight-bold', {
                            'is-danger': props.state === 'failed',
                            'is-success': props.state === 'complete',
                        })}
                    >
                        {props.message}
                    </p>
                  {
                    votedAt &&
                    <small className="is-size-7 has-text-grey is-block" style={{paddingTop:10}}> {votedAt} </small>
                  }

                </section>
                <footer className="modal-card-foot">
                    <button className="button is-fullwidth is-white is-light" onClick={() => close()}>閉じる</button>
                </footer>
            </div>
        </div>
    );
    function close() {
        window.history.replaceState('', '', location.pathname);
        setActive(false);
    }
}
