import React from 'react';
import classNames from 'classnames';
import { route, mount, compose } from 'navi';
import { Helmet } from 'react-navi-helmet-async';
import History from '@2021-mom-web/components/src/history';
import List from '@2021-mom-web/components/src/list';
import Card from '@2021-mom-web/components/src/card';
import News from '@2021-mom-web/components/src/news';
import Navigation from '@2021-mom-web/components/src/navigation';
import Schedule from '@2021-mom-web/components/src/schedule';
import Announcement from '@2021-mom-web/components/src/announcement';
import Entry from '@2021-mom-web/components/src/types/entry';
import {MediaBox, MediaBoxes} from "@2021-mom-web/components/src/mediabox";
import history from '../../json/history.json';
import news from '../../json/news.json';
import { ReactComponent as Blur } from '../../assets/blur.svg';
import { ReactComponent as LogoTop } from '../../assets/mrofmr.svg';
import { ReactComponent as Logo2020 } from '../../assets/logo_2020.svg';
import { ReactComponent as Mrcolle } from '../../assets/mrcolle.svg';
import { ReactComponent as Misscolle } from '../../assets/misscolle.svg';
import { ReactComponent as Ray } from '../../assets/ray.svg';
import { ReactComponent as Cancam } from '../../assets/cancam.svg';
import { ReactComponent as Datetime } from '../../assets/datetime.svg';
import { ReactComponent as Rize } from '../../assets/rize.svg';
import { ReactComponent as MensRize } from '../../assets/mensrize.svg';
import { ReactComponent as Anniversaire } from '../../assets/anniversaire.svg';
import { ReactComponent as Rageblue } from '../../assets/rageblue.svg';
import { ReactComponent as Creaub } from '../../assets/creaub.svg';
import { list } from '../../json/entry.json';
import loadingImage from '../../assets/loading.png';

export default compose(
  mount({
    '/': route({
      getView: ({ context }) => (
        <>
          <Helmet>
            <meta content={`${process.env.REACT_APP_URL}`} property="og:url" />
            <meta content={`${process.env.REACT_APP_OGP_URL}`} property="og:image" />
          </Helmet>
          <App />
        </>
      ),
      title: process.env.REACT_APP_WEBSITE_NAME,
    }),
  }),
);

const App = () => {
  return (
    <div id="top">
      <section className="hero is-fullheight is-black">
        <div className="hero-cover">
          <figure className="blur">
            <Blur />
          </figure>
        </div>
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title is-spaced mb-6">
              <LogoTop style={{ maxWidth: 600, maxHeight: 210 }} />
            </h1>
            <p className="subtitle">
              <Datetime />
            </p>
          </div>
        </div>
        <div className="hero-foot is-relative has-text-centered">
          <Announcement href="#award">
              グランプリ、各賞が決定しました。
          </Announcement>
          <Navigation
            additional={
              <li>
                <a href="https://missofmiss.jp/" target="_blank">
                  <span className="icon is-small">
                    <i className="fas fa-external-link-alt" aria-hidden="true" />
                  </span>
                  <span>MISS OF MISS</span>
                </a>
              </li>
            }
          />
        </div>
      </section>
      {/*
        Award
      */}
      <section id="award" className="section has-background-gold-stripe">
        <div className="container">
          <div className="mb-6">
            <h1 className="title as-section-title">AWARD</h1>
            <h2 className="subtitle">受賞者一覧</h2>
          </div>
          {/* グランプリ */}
          <div className="mb-6">
            <h1 className="title has-text-link is-size-3 as-section-title has-text-gold has-text-weight-bold">グランプリ</h1>
            <h2 className="subtitle" />
          </div>
          <div className="columns is-centered is-mobile mb-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <Card
                  className="is-mrofmr"
                  loadingImage={loadingImage}
                  href={`/profiles/${15}`}
                  entry={{
                      "id": 15,
                      "mysta": "956684",
                      "showroom": "",
                      "college": "立教大学",
                      "name": "鈴木 廉",
                      "furigana": "すずき れん",
                      "hometown": "東京都",
                      "grade": 4,
                      "department": "経済学部",
                      "twitter": "rikkyo20mr_4",
                      "instagram": "rikkyo20mr_4",
                      "hobby": "ダンス、歌",
                      "skill": "ダンス、歌",
                      "dream": "有名なアーティストになって大きなステージに立つ",
                      "enthusiasm": "グランプリ目指して頑張ります",
                      "circle": "",
                      "magazine": "",
                      "birthday": "1998/08/11",
                      "images": [1, 2, 3, 4, 5, 6],
                      "finalist": true
                  }}
              />
            </div>
          </div>
          {/* 準グランプリ */}
          <div className="mb-6">
            <h1 className="title is-size-5 as-section-title has-text-weight-bold">準グランプリ</h1>
            <h2 className="subtitle" />
          </div>
          <div className="columns is-centered is-mobile mb-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <Card
                  className="is-mrofmr"
                  loadingImage={loadingImage}
                  href={`/profiles/${4}`}
                  entry={{
                      "id": 4,
                      "mysta": "956666",
                      "showroom": "",
                      "college": "学習院大学",
                      "name": "福島 有稀",
                      "furigana": "ふくしま ゆうき",
                      "hometown": "栃木県",
                      "grade": 2,
                      "department": "理学部数学科",
                      "twitter": "fyuuki0112",
                      "instagram": "yuuuki.0112",
                      "hobby": "映画鑑賞、ウィンドウショッピング",
                      "skill": "早口言葉、美味しいコーヒーを作ること",
                      "dream": "俳優",
                      "enthusiasm": "グランプリ目指して頑張りますので最後まで応援お願いします！",
                      "circle": "演劇",
                      "magazine": "ファインボーイズ",
                      "birthday": "2000/01/12",
                      "images": [1, 2, 3, 4, 5, 6],
                      "finalist": true
                  }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <Card
                  className="is-mrofmr"
                  loadingImage={loadingImage}
                  href={`/profiles/${7}`}
                  entry={{
                      "id": 7,
                      "mysta": "956670",
                      "showroom": "",
                      "college": "拓殖大学",
                      "name": "金井 優希",
                      "furigana": "かない ゆうき",
                      "hometown": "静岡県",
                      "grade": 2,
                      "department": "商学部",
                      "twitter": "tu___mr201",
                      "instagram": "tu___mr201",
                      "hobby": "野球、アカペラ、ドラマ鑑賞",
                      "skill": "歌、高校野球の審判のモノマネ",
                      "dream": "アカデミー賞を受賞すること",
                      "enthusiasm": "MR OF MR に出場することができ、とてもワクワクしています！\nいつも応援頂いている皆さんへの感謝の気持ちを大切に、そして、これから始まる新しい出会いを楽しみにしています。\n全力で頑張ります!!",
                      "circle": "アカペラ、野球",
                      "magazine": "MEN'S NON-NO",
                      "birthday": "2000/07/11",
                      "images": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                      "finalist": true
                  }}
              />
            </div>
          </div>
          {/* 審査員特別賞 */}
          <div className="mb-6">
            <h1 className="title is-size-5 as-section-title has-text-weight-bold">審査員特別賞</h1>
            <h2 className="subtitle" />
          </div>
          <div className="columns is-centered is-multiline is-mobile mb-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <Card
                  className="is-mrofmr"
                  loadingImage={loadingImage}
                  href={`/profiles/${2}`}
                  entry={{
                      "id": 2,
                      "mysta": "956664",
                      "showroom": "",
                      "college": "慶應義塾大学",
                      "name": "白木 慈恩",
                      "furigana": "しらき じおん",
                      "hometown": "東京都",
                      "grade": 2,
                      "department": "商学部",
                      "twitter": "mrkeio2020_no1",
                      "instagram": "mrkeio2020_no1",
                      "hobby": "観劇、野球観戦",
                      "skill": "ダンス、水泳、テニス",
                      "dream": "舞台俳優",
                      "enthusiasm": "学校のグランプリだけでなく今年のミスターNo.１を目指します！",
                      "circle": "",
                      "magazine": "メンズノンノ",
                      "birthday": "1999/12/21",
                      "images": [1, 2, 3, 4, 5],
                      "finalist": true
                  }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <Card
                  className="is-mrofmr"
                  loadingImage={loadingImage}
                  href={`/profiles/${5}`}
                  entry={{
                      "id": 5,
                      "mysta": "956667",
                      "showroom": "",
                      "college": "千葉大学",
                      "name": "大崎 智也",
                      "furigana": "おおさき ともや",
                      "hometown": "神奈川県",
                      "grade": 4,
                      "department": "工学部",
                      "twitter": "chiba2020_mrNo2",
                      "instagram": "tomoya_osaki_",
                      "hobby": "野球観戦、フットサル",
                      "skill": "ボウリング、ビリヤード",
                      "dream": "気象キャスター",
                      "enthusiasm": "今年しかできない経験なので楽しみながら頑張ります。",
                      "circle": "サッカーサークル",
                      "magazine": "",
                      "birthday": "1997/08/30",
                      "images": [1, 2, 3, 4, 5],
                      "finalist": true
                  }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <Card
                  className="is-mrofmr"
                  loadingImage={loadingImage}
                  href={`/profiles/${14}`}
                  entry={{
                      "id": 14,
                      "mysta": "956683",
                      "showroom": "",
                      "college": "桜美林大学",
                      "name": "米倉 聖真",
                      "furigana": "よねくら せいま",
                      "hometown": "愛知県",
                      "grade": 3,
                      "department": "健康福祉学群",
                      "twitter": "mr2obirin_20",
                      "instagram": "mr2_obirin20",
                      "hobby": "古着屋巡り、お酒作り",
                      "skill": "メンズヘアセット、野球",
                      "dream": "誰かの背中を押せる存在になること",
                      "enthusiasm": "大学のコンテストでは悔しい思いをしたのでその悔しさをバネににグランプリに輝いて見せます！",
                      "circle": "バドミントン",
                      "magazine": "",
                      "birthday": "1999/11/13",
                      "images": [1, 2, 3, 4, 5, 6, 7, 8, 9],
                      "finalist": true
                  }}
              />
            </div>
          </div>
          {/* ミスターメンズリゼ賞/ミスターリゼウォーク賞 */}
          <div className="columns is-centered is-mobile mb-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title has-text-weight-bold">ミスター<br className="is-hidden-tablet" />メンズリゼ賞</h1>
                <h2 className="subtitle" />
              </div>
              <Card
                  className="is-mrofmr"
                  loadingImage={loadingImage}
                  href={`/profiles/${11}`}
                  entry={{
                      "id": 11,
                      "mysta": "956680",
                      "showroom": "",
                      "college": "駒澤大学",
                      "name": "増島 拓実",
                      "furigana": "ますじま たくみ",
                      "hometown": "神奈川県",
                      "grade": 3,
                      "department": "経済学部",
                      "twitter": "koma2020_mr4",
                      "instagram": "takumi_masujima",
                      "hobby": "カメラ",
                      "skill": "長く寝れること",
                      "dream": "影響力のある人間になりたいです",
                      "enthusiasm": "自分らしく頑張ります！",
                      "circle": "",
                      "magazine": "Men's Non-No、smart",
                      "birthday": "2000/02/01",
                      "images": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                      "finalist": true
                  }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title has-text-weight-bold">ミスター<br className="is-hidden-tablet" />リゼウォーク賞</h1>
                <h2 className="subtitle" />
              </div>
              <Card
                  className="is-mrofmr"
                  loadingImage={loadingImage}
                  href={`/profiles/${15}`}
                  entry={{
                      "id": 15,
                      "mysta": "956684",
                      "showroom": "",
                      "college": "立教大学",
                      "name": "鈴木 廉",
                      "furigana": "すずき れん",
                      "hometown": "東京都",
                      "grade": 4,
                      "department": "経済学部",
                      "twitter": "rikkyo20mr_4",
                      "instagram": "rikkyo20mr_4",
                      "hobby": "ダンス、歌",
                      "skill": "ダンス、歌",
                      "dream": "有名なアーティストになって大きなステージに立つ",
                      "enthusiasm": "グランプリ目指して頑張ります",
                      "circle": "",
                      "magazine": "",
                      "birthday": "1998/08/11",
                      "images": [1, 2, 3, 4, 5, 6],
                      "finalist": true
                  }}
              />
            </div>
          </div>
          {/* アナトレ賞/ANNIVERSAIRE賞 */}
          <div className="columns is-centered is-mobile mb-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title has-text-weight-bold">アナトレ賞</h1>
                <h2 className="subtitle" />
              </div>
              <Card
                  className="is-mrofmr"
                  loadingImage={loadingImage}
                  href={`/profiles/${5}`}
                  entry={{
                      "id": 5,
                      "mysta": "956667",
                      "showroom": "",
                      "college": "千葉大学",
                      "name": "大崎 智也",
                      "furigana": "おおさき ともや",
                      "hometown": "神奈川県",
                      "grade": 4,
                      "department": "工学部",
                      "twitter": "chiba2020_mrNo2",
                      "instagram": "tomoya_osaki_",
                      "hobby": "野球観戦、フットサル",
                      "skill": "ボウリング、ビリヤード",
                      "dream": "気象キャスター",
                      "enthusiasm": "今年しかできない経験なので楽しみながら頑張ります。",
                      "circle": "サッカーサークル",
                      "magazine": "",
                      "birthday": "1997/08/30",
                      "images": [1, 2, 3, 4, 5],
                      "finalist": true
                  }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title">ANNIVERSAIRE賞</h1>
                <h2 className="subtitle" />
              </div>
              <Card
                  className="is-mrofmr"
                  loadingImage={loadingImage}
                  href={`/profiles/${6}`}
                  entry={{
                      "id": 6,
                      "mysta": "956669",
                      "showroom": "",
                      "college": "慶應義塾大学",
                      "name": "天野 歩",
                      "furigana": "あまの あゆむ",
                      "hometown": "神奈川県",
                      "grade": 3,
                      "department": "経済学部",
                      "twitter": "mrkeio2020_no5",
                      "instagram": "mrkeio2020_no5",
                      "hobby": "コーヒーを淹れること、合氣道、食べること",
                      "skill": "起きて五分で家を出ること",
                      "dream": "幸せな家庭を築くこと",
                      "enthusiasm": "多くの人に影響を与えられるような期間にしたい。",
                      "circle": "",
                      "magazine": "",
                      "birthday": "1999/10/18",
                      "images": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                      "finalist": true
                  }}
              />
            </div>
          </div>
          {/* La Crème au beurre賞/RAGEBLUE賞 */}
          <div className="columns is-centered is-mobile mb-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title is-no-uppercase">La Crème <br />au beurre賞</h1>
                <h2 className="subtitle" />
              </div>
              <Card
                  className="is-mrofmr"
                  loadingImage={loadingImage}
                  href={`/profiles/${2}`}
                  entry={{
                      "id": 2,
                      "mysta": "956664",
                      "showroom": "",
                      "college": "慶應義塾大学",
                      "name": "白木 慈恩",
                      "furigana": "しらき じおん",
                      "hometown": "東京都",
                      "grade": 2,
                      "department": "商学部",
                      "twitter": "mrkeio2020_no1",
                      "instagram": "mrkeio2020_no1",
                      "hobby": "観劇、野球観戦",
                      "skill": "ダンス、水泳、テニス",
                      "dream": "舞台俳優",
                      "enthusiasm": "学校のグランプリだけでなく今年のミスターNo.１を目指します！",
                      "circle": "",
                      "magazine": "メンズノンノ",
                      "birthday": "1999/12/21",
                      "images": [1, 2, 3, 4, 5],
                      "finalist": true
                  }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title"><br />RAGEBLUE賞</h1>
                <h2 className="subtitle" />
              </div>
              <Card
                  className="is-mrofmr"
                  loadingImage={loadingImage}
                  href={`/profiles/${11}`}
                  entry={{
                      "id": 11,
                      "mysta": "956680",
                      "showroom": "",
                      "college": "駒澤大学",
                      "name": "増島 拓実",
                      "furigana": "ますじま たくみ",
                      "hometown": "神奈川県",
                      "grade": 3,
                      "department": "経済学部",
                      "twitter": "koma2020_mr4",
                      "instagram": "takumi_masujima",
                      "hobby": "カメラ",
                      "skill": "長く寝れること",
                      "dream": "影響力のある人間になりたいです",
                      "enthusiasm": "自分らしく頑張ります！",
                      "circle": "",
                      "magazine": "Men's Non-No、smart",
                      "birthday": "2000/02/01",
                      "images": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                      "finalist": true
                  }}
              />
            </div>
          </div>

          {/* mysta賞/MR COLLE賞 */}
          <div className="columns is-centered is-mobile mb-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title is-no-uppercase">mysta賞</h1>
                <h2 className="subtitle" />
              </div>
              <Card
                  className="is-mrofmr"
                  loadingImage={loadingImage}
                  href={`/profiles/${7}`}
                  entry={{
                      "id": 7,
                      "mysta": "956670",
                      "showroom": "",
                      "college": "拓殖大学",
                      "name": "金井 優希",
                      "furigana": "かない ゆうき",
                      "hometown": "静岡県",
                      "grade": 2,
                      "department": "商学部",
                      "twitter": "tu___mr201",
                      "instagram": "tu___mr201",
                      "hobby": "野球、アカペラ、ドラマ鑑賞",
                      "skill": "歌、高校野球の審判のモノマネ",
                      "dream": "アカデミー賞を受賞すること",
                      "enthusiasm": "MR OF MR に出場することができ、とてもワクワクしています！\nいつも応援頂いている皆さんへの感謝の気持ちを大切に、そして、これから始まる新しい出会いを楽しみにしています。\n全力で頑張ります!!",
                      "circle": "アカペラ、野球",
                      "magazine": "MEN'S NON-NO",
                      "birthday": "2000/07/11",
                      "images": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                      "finalist": true
                  }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title is-no-uppercase">MR COLLE賞</h1>
                <h2 className="subtitle" />
              </div>
              <Card
                  className="is-mrofmr"
                  loadingImage={loadingImage}
                  href={`/profiles/${13}`}
                  entry={{
                      "id": 13,
                      "mysta": "956682",
                      "showroom": "",
                      "college": "桜美林大学",
                      "name": "川口 友雅",
                      "furigana": "かわぐち ゆうが",
                      "hometown": "大阪府",
                      "grade": 2,
                      "department": "芸術文化学群",
                      "twitter": "mr3_obirin20",
                      "instagram": "mr3_obirin20",
                      "hobby": "野球観戦、映画鑑賞、ポテト食べ漁り",
                      "skill": "コミュニケーションを取る、リーダーシップを執る、暗記、スタバカスタムを教える、ピアノ",
                      "dream": "人の心を動かす俳優になる事",
                      "enthusiasm": "1人でも多くの人に存在を知ってもらうと共に夢の続きを再び見せます！",
                      "circle": "",
                      "magazine": "",
                      "birthday": "2000/04/05",
                      "images": [1, 2, 3, 4, 5, 6, 7, 8],
                      "finalist": true
                  }}
              />
            </div>
          </div>
        </div>
      </section>



      {/*
        Entries
      */}
      <section id="entries" className="section">
        <div className="container">
          <div className="mb-6">
            <h1 className="title as-section-title">FINALIST</h1>
            <h2 className="subtitle">ファイナリスト</h2>
          </div>
          <div className="columns is-multiline is-mobile mb-6">
            <List list={list} finalist>
              {(list: Entry[]) =>
                list.map((item: Entry, index: number) => (
                  <div
                    className={classNames('column is-3-desktop is-4-tablet is-6-mobile')}
                    key={item.id}
                  >
                    <Card
                      className="is-mrofmr"
                      loadingImage={loadingImage}
                      href={`/profiles/${item.id}`}
                      entry={item}
                    />
                  </div>
                ))
              }
            </List>
          </div>
        </div>
      </section>
      {/*
        Schedule
      */}
      <section id="schedule" className="section has-background-gradient is-mrofmr">
        <div className="container">
          <div className="mb-6">
            <h1 className="title as-section-title">Schedule</h1>
            <h2 className="subtitle mb-3">日本一のミスターキャンパスが決まるまで</h2>
            <p className="is-size-7 has-text-white has-text-centered">
              ※ スケジュールは予告なく変更されることがあります。
            </p>
          </div>
          <Schedule />
        </div>
      </section>
      {/*
        Information
      */}
      <section id="information" className="section">
        <div className="container" style={{ maxWidth: 740 }}>
          <div className="mb-6">
            <h1 className="title as-section-title">Event</h1>
            <h2 className="subtitle">イベント情報</h2>
          </div>
          <p className="has-text-centered is-size-6-mobile is-size-5 has-text-weight-bold mb-3">
            MISS OF MISS CAMPUS QUEEN CONTEST 2021 supported by リゼクリニック
          </p>
          <p className="has-text-centered is-size-6-mobile is-size-5 has-text-weight-bold mb-6">
            MR OF MR CAMPUS CONTEST 2021 supported by メンズリゼ
          </p>
          <div className="is-margin-bottom-3 is-margin-top-2">
            <h1 className="title is-gray is-size-6 has-text-weight-bold as-section-title">MC</h1>
            <h2 className="subtitle"></h2>
          </div>
          <div className="columns is-centered is-mobile is-margin-bottom-6">
            <div className="column is-8-tablet is-10-mobile has-text-centered">
              <p className="is-size-6 is-margin-bottom-1">
                <span className="is-size-4 has-text-weight-bold">NON STYLE</span>
                <br />
              </p>
              <div id="mc" className="card">
                <div className="card-image">
                  <figure className="image is-max-480x480">
                    <img src="/image/nonstyle.jpg" width="320" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
          <div className="columns is-centered is-mobile is-margin-bottom-6 mt-6">
            <div className="column is-8-tablet is-10-mobile has-text-centered">
              <p className="is-size-6 is-margin-bottom-1">
                <span className="is-size-5 has-text-weight-bold">高見 侑里</span>
                <br className="is-hidden-tablet" />
                <span className="is-size-7-mobile">（フリーアナウンサー）</span>
                <br />
                <br className="is-hidden-mobile" />
                <span className="is-size-7-mobile">
                  MISS OF MISS CAMPUS QUEEN CONTEST <br className="is-hidden-tablet" />
                  2008 グランプリ
                </span>
              </p>
              <div id="mc" className="card">
                <div className="card-image">
                  <figure className="image is-max-480x480">
                    <img src="/image/mc.jpg" width="320" />
                  </figure>
                </div>
                <div className="card-content">
                  <div className="content has-text-left is-size-7-mobile">
                    12年前に参加させていただいたMISS OF MISS CAMPUS QUEEN
                    CONTESTにこうしてまた携わらせていただき、嬉しさと懐かしい気持ちでいっぱいです。
                    <br />
                    エントリーされたお一人お一人が輝けるように、またこうした状況で今年も開催できたことに感謝しながら、皆様と温かな時間を共有できるよう真摯に務めてさせていただきたいと思います。
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="is-margin-bottom-3 mt-6">
            <h1 className="title is-gray is-size-6 has-text-weight-bold as-section-title">ゲスト</h1>
            <h2 className="subtitle"></h2>
          </div>
          <div className="columns is-centered is-mobile is-margin-bottom-6">
            <div className="column is-8-tablet is-10-mobile has-text-centered">
              <p className="is-size-6 is-margin-bottom-1">
                <span className="is-size-5 has-text-weight-bold">山賀 琴子</span>
                <br className="is-hidden-tablet" />
                <span className="is-size-7-mobile">（モデル・ブランドディレクター）</span>
                <br />
                <br className="is-hidden-mobile" />
                <span className="is-size-7-mobile">
                  MISS OF MISS CAMPUS QUEEN CONTEST <br className="is-hidden-tablet" />
                  2016 グランプリ
                </span>
              </p>
              <div id="guest" className="card">
                <div className="card-image">
                  <figure className="image is-max-480x480">
                    <img src="/image/guest.jpg" width="320" />
                  </figure>
                </div>
                <div className="card-content">
                  <div className="content has-text-left is-size-7-mobile">
                    MISS OF MISS CAMPUS QUEEN
                    CONTESTに参加してから4年が経ち、今回また携わらせていただくことができとても嬉しく思います。
                    <br />
                    出場者の皆さまにお会いできることを楽しみにしています。
                    <br />
                    そして皆さまと素晴らしい時間を過ごせるように精一杯務めたいと思っていますのでよろしくお願い致します。
                  </div>
                </div>
              </div>
            </div>
          </div>
          <table className="table is-fullwidth as-information mt-6">
            <tbody>
              <tr>
                <th className="is-narrow">会場</th>
                <td>
                  <a
                    className="has-text-grey-dark"
                    style={{ textDecoration: 'underline' }}
                    href="https://www.ex-theater.com/"
                    target="_blank"
                  >
                    EX THEATER ROPPONGI
                  </a>{' '}
                  (東京都港区西麻布1-2-9)
                </td>
              </tr>
              <tr>
                <th className="is-narrow">日時</th>
                <td>2021年3月2日(火)</td>
              </tr>
              <tr>
                <th className="is-narrow">オンライン配信</th>
                <td><a
                    className="has-text-grey-dark"
                    style={{ textDecoration: 'underline', wordBreak: "break-all" }}
                    href="https://17.live/profile/r/14269982" target="_blank">https://17.live/profile/r/14269982</a></td>
              </tr>
              <tr>
                <th className="is-narrow">主催</th>
                <td>学生団体MARKS / 株式会社エイジ・エンタテインメント</td>
              </tr>
              <tr>
                <th className="is-narrow">企画制作</th>
                <td>
                  <div className="images">
                    <a href="https://misscolle.com/" target="_blank">
                      <figure className="image mt-0">
                        <Misscolle />
                      </figure>
                    </a>
                    <a href="https://mrcolle.com/" target="_blank">
                      <figure className="image mt-0">
                        <Mrcolle />
                      </figure>
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <th className="is-narrow">特別協賛</th>
                <td>
                  <div className="images">
                    <a href="https://www.rizeclinic.com/" target="_blank">
                      <figure className="image">
                        <Rize height={50} />
                      </figure>
                    </a>
                    <a href="https://www.mens-rize.com/" target="_blank">
                      <figure className="image">
                        <MensRize height={50} />
                      </figure>
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <th className="is-narrow">特別協力</th>
                <td>
                  <div id="sponsors" className="images">
                    <a href="http://anatore.fujitv.co.jp/" target="_blank">
                      <figure id="anatore" className="image mt-0 mb-5">
                        <img src="/image/anatore.png" />
                      </figure>
                    </a>
                    <a href="https://www.tv-asahi-ask.co.jp/" target="_blank">
                      <figure id="ask" className="image mt-0 mb-6">
                        <img src="/image/ask.png" />
                      </figure>
                    </a>
                    <a href="https://cancam.jp/" target="_blank">
                      <figure id="cancam" className="image mt-0 mb-6">
                        <Cancam height={40} />
                      </figure>
                    </a>
                    <a href="https://www.mensnonno.jp/" target="_blank">
                      <figure id="mensnonno" className="image mt-0 mb-6">
                        <img src="/image/mensnonno.png" />
                      </figure>
                    </a>
                    <a href="https://nonno.hpplus.jp/" target="_blank">
                      <figure id="nonno" className="image mt-0 mb-6">
                        <img src="/image/nonno.png" />
                      </figure>
                    </a>
                    <a href="https://ray-web.jp/" target="_blank">
                      <figure id="ray" className="image mt-0 mb-6">
                        <Ray height={45} />
                      </figure>
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <th className="is-narrow">協賛</th>
                <td>
                  <div className="images">
                    <a href="https://www.anniversaire.co.jp/" target="_blank">
                      <figure id="anniversaire" className="image mt-1 mb-6">
                        <Anniversaire height={45} />
                      </figure>
                    </a>
                    <a href="https://creab.official.ec/" target="_blank">
                      <figure id="creaub" className="image mt-0 mb-6">
                        <Creaub height={40} />
                      </figure>
                    </a>
                    <a href="https://www.dot-st.com/rageblue/" target="_blank">
                      <figure id="rageblue" className="image mt-0 mb-6">
                        <Rageblue height={45} />
                      </figure>
                    </a>
                    <a href="https://www.mysta.tv/" target="_blank">
                      <figure id="mysta" className="image mt-1 mb-6">
                        <img src="/image/mysta.png" height={40} style={{maxHeight: 30, marginTop: 4}} />
                      </figure>
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <th className="is-narrow">日程</th>
                <td>
                  全国予選：2021/2/8(月)～2021/2/14(日)<br />
                  <br />
                  決勝：2021/2/21(日)～2021/2/27(土)<br />
                  <br />
                  表彰式：2021/3/2(火)
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      {/*
        History
      */}
      <section id="history" className="section is-mrofmr">
        <div className="container">
          <div className="mb-6">
            <h1 className="title as-section-title">History</h1>
            <h2 className="subtitle">ミスターオブミスターの歴史</h2>
          </div>
          <History json={history} />
        </div>
      </section>
      {/*
        Media
      */}
      <section id="media" className="section">
        <div className="container" style={{maxWidth: 780}}>
          <div className="mb-6">
            <h1 className="title as-section-title">Media</h1>
            <h2 className="subtitle">掲載メディア</h2>
          </div>
          <MediaBoxes>
            <div style={{ maxWidth: 250 }}>
              <MediaBox
                  href="https://mdpr.jp/news/detail/2429581"
                  src="https://img-mdpr.freetls.fastly.net/article/6EXu/nm/6EXu-G98K3E7kIn0XzuUj5z88f7cEoVvtNLAloIXMK4.jpg?width=700&disable=upscale&auto=webp"
                  title="[モデルプレス]日本一のミスターキャンパスを決めるコンテスト、全国予選出場者決定＜MR OF MR CAMPUS CONTEST 2021＞"
                  media="modelpress"
                  datetext="2021.02.08"
                  icon="https://pbs.twimg.com/profile_images/1156411788985978880/vJyWfh5H_400x400.png"
              />
            </div>
            <div style={{ maxWidth: 250 }}>
              <MediaBox
                  href="https://mdpr.jp/news/2394179"
                  src="https://img-mdpr.freetls.fastly.net/article/IcxX/nm/IcxX3Ytplb1051HHGDkvgBzZuEGdue3Eixym8CDLFI8.jpg?width=700&disable=upscale&auto=webp"
                  title="[モデルプレス]日本一のミスターキャンパスを決めるコンテスト、第1弾出場者発表＜MR OF MR CAMPUS CONTEST 2021＞"
                  media="modelpress"
                  datetext="2021.01.15"
                  icon="https://pbs.twimg.com/profile_images/1156411788985978880/vJyWfh5H_400x400.png"
              />
            </div>
            <div style={{ maxWidth: 250 }}>
              <MediaBox
                  href="https://thetv.jp/news/detail/1017515/"
                  src="https://thetv.jp/i/nw/1017515/10151559.jpg?w=615"
                  title='[ザ・テレビジョン]全国のミスターキャンパスの中から"日本一のイケメン大学生"を決定するコンテスト、第一弾出場者発表'
                  media="ザ・テレビジョン"
                  datetext="2021.01.15"
                  icon="https://pbs.twimg.com/profile_images/842775920091975680/7igeN6FE_400x400.jpg"
              />
            </div>
          </MediaBoxes>
        </div>
      </section>

      {/*
         News
      */}
      <section id="news" className="section">
        <div className="container" style={{maxWidth: 540}}>
          <div className="columns">
            <div className="column">
              <div className="mb-6">
                <h1 className="title as-section-title">News</h1>
                <h2 className="subtitle">お知らせ</h2>
              </div>
              <News json={news} />
            </div>
          </div>
        </div>
      </section>

      <nav className="navbar">
        <div className="columns is-variable is-4 is-mobile is-centered is-vcentered">
          <div className="column is-narrow">
            <figure className="image">
              <Logo2020 width={120} />
            </figure>
          </div>
          <div className="column is-narrow">
            <a
              href="https://2020.mrofmr.jp"
              target="_blank"
              rel="noopener noreferrer"
              className="button is-size-7-mobile is-black has-text-weight-bold"
            >
              <span>昨年の様子はこちら</span>
              <span className="icon">
                <i className="fas fa-chevron-right" />
              </span>
            </a>
          </div>
        </div>
      </nav>
      <footer className="footer has-background-black is-padding-bottom-3">
        <div className="content has-text-centered">
          <LogoTop width={150} />
          <p className="is-size-7 mt-3">Copyright © 2020 株式会社エイジ・エンタテインメント All Rights Reserved.</p>
        </div>
        <a className="button is-size-7 is-light is-dark" href="mailto:inquiry@missofmiss.jp">
          <span className="icon">
            <i className="far fa-envelope" />
          </span>
          <span>お問い合わせ</span>
        </a>
      </footer>
    </div>
  );
};
