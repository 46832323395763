import React from 'react';

export default function InstagramButton(props: React.AnchorHTMLAttributes<HTMLAnchorElement>) {
    return (
        <a {...props} className="button is-white">
            <span className="icon is-small">
                <img src="/image/instagram.svg" />
            </span>
        </a>
    );
}
