import React from 'react';

interface TimelineProps {}

const Timeline: React.FC<TimelineProps & React.HTMLAttributes<HTMLDivElement>> = (props) => (
  <div className="timeline">
    <table className="table as-timeline">
      <tbody>
        <tr>
          <td className="is-narrow">
            <svg width="20px">
              <rect x="6.5" y="17" width="10" height="10" rx="11" ry="11" stroke="white" strokeWidth="1" fill="white" />
              <line x1="12" y1="23" x2="10" y2="520" stroke="white" strokeWidth="1" />
            </svg>
          </td>
          <td className="centered is-padding-bottom-3">
            <div>
              <p className="is-size-4">全国予選</p>
              <p>2021/02/08(月)～2021/02/14(日)</p>
              <p className="tag is-light is-link mt-3 mb-2">WEB投票</p>
              <p>2/8(月)12:00〜2/14(日)23:59</p>
              <p className="tag is-light is-link mt-3 mb-2">特別協賛審査</p>
              <p>2/8(月)12:00〜2/14(日)23:59</p>
              <p>ランキング1位特典：ファイナル審査進出権</p>
              <p className="tag is-light is-link mt-3 mb-2">mysta審査</p>
              <p>2/8(月)12:00～2/14(日)23:59</p>
              <p>ランキング1位特典：ファイナル審査進出権</p>
            </div>
          </td>
        </tr>
        <tr>
          <td className="is-narrow">
            <svg width="20px">
              <rect x="5.5" y="17" width="10" height="10" rx="11" ry="11" stroke="white" strokeWidth="1" fill="white" />
            </svg>
          </td>
          <td className="centered">
            <div>
              <p className="is-size-4">決勝</p>
              <p>2021/02/21(日)～2021/02/27(土)</p>
              <p className="tag is-light is-link mt-3 mb-2">WEB投票</p>
              <p>2/21(日)12:00～2/27(土)23:59</p>
              <p className="tag is-light is-link mt-3 mb-2">特別協賛審査</p>
              <p>2/21(日)12:00～2/27(土)23:59</p>
              <p className="tag is-light is-link mt-3 mb-2">mysta審査</p>
              <p>2/21(日)12:00～2/27(土)23:59</p>
            </div>
          </td>
        </tr>
        <tr>
          <td className="is-narrow">
            <svg width="20px">
              <rect x="5.5" y="17" width="10" height="10" rx="11" ry="11" stroke="white" strokeWidth="1" fill="white" />
            </svg>
          </td>
          <td className="centered">
            <div>
              <p className="is-size-4">表彰式</p>
              <p>
                2021/03/02(火)
                <br />
                @EX THEATER ROPPONGI
              </p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

export default Timeline;
