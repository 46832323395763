import React from 'react';
import { mount, compose } from 'navi';
import top from "./top";
import entry from "./entry";
import thanks from "./thanks";
import entries from "./entries";
import profiles from "./profiles";
import news from "./news";
const fromPairs = require('lodash.frompairs');

const routes = compose(
    mount({
        '/': top,
        '/entry': entry,
        '/thanks': thanks,
        '/entries': entries,
        '/news': news,
        '/profiles':mount({
            ...fromPairs(profiles.map(profile => [`/${profile.id}`, profile.getPage])),
        }),
    }),
);

export default routes;