import React from 'react';
import {Link} from "react-navi";

export default function Announcement(props: Link.Props) {
    return (
        <Link {...props} className="announcement">
            <span className="announcement-tag">New</span>
            <span className="announcement-message">{props.children}</span>
        </Link>
    )
}