import React from 'react';
//@ts-ignore
import { ReactComponent as Logo } from './twitter.svg';

export default function TwitterButton(props: React.AnchorHTMLAttributes<HTMLAnchorElement>) {
    return (
        <a {...props} className="button is-white">
            <span className="icon is-small">
                <img src="/image/twitter.svg" />
            </span>
        </a>
    );
}