import React from 'react';
import { LazyImageFull, ImageState } from 'react-lazy-images';
interface PhotoProps {
  onBackground?: boolean;
  loadingImage: any;
  src: string;
}

const Photo: React.FC<
  PhotoProps & React.AnchorHTMLAttributes<HTMLAnchorElement> & React.ImgHTMLAttributes<HTMLImageElement>
> = (props) => {
  const style = {
    backgroundImage: props.onBackground ? `url(${props.src})` : 'none',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  };
  return (
    <a href={props.href} data-fancybox="gallery">
      <figure className="image is-1by1" style={props.onBackground ? style : undefined}>
        <picture>
          {props.onBackground ? (
            <LazyImageFull src={props.src} alt={props.alt}>
              {({ imageProps, imageState, ref }) => {
                if (imageState === ImageState.NotAsked) {
                  return <img alt={props.alt} src={props.loadingImage} ref={ref} />;
                }
                return <img alt={props.alt} {...imageProps} style={{ opacity: props.onBackground ? 0 : 1 }} />;
              }}
            </LazyImageFull>
          ) : (
            <LazyImageFull src={props.src} alt={props.alt}>
              {({ imageProps, imageState, ref }) => {
                if (imageState === ImageState.NotAsked) {
                  return <img alt={props.alt} src={props.loadingImage} ref={ref} />;
                }
                return <img alt={props.alt} {...imageProps} ref={ref} />;
              }}
            </LazyImageFull>
          )}
        </picture>
      </figure>
    </a>
  );
};

export default Photo;
