import React, { useEffect } from 'react';
import { route, mount, compose } from 'navi';
import { Helmet } from 'react-navi-helmet-async';
import { Link } from 'react-navi';
import { ReactComponent as Logo } from '../../assets/mrofmr.svg';

export default compose(
  mount({
    '/': route({
      getView: ({ context }) => (
        <>
          <Helmet>
            <meta content={`${process.env.REACT_APP_URL}`} property="og:url" />
            <meta content={`${process.env.REACT_APP_OGP_URL}`} property="og:image" />
          </Helmet>
          <App />
        </>
      ),
      title: process.env.REACT_APP_WEBSITE_NAME,
    }),
  }),
);

const App = () => {
  useEffect(() => {
    try {
      // @ts-ignore
      Formrun.init('.formrun');
    } catch {}
  }, []);
  return (
    <div id="entry">
      <nav className="navbar is-black" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <Link className="navbar-item" href="/">
            <Logo />
          </Link>
        </div>
      </nav>
      <div className="section has-background-black">
        <div className="container is-slim">
          <h1 className="title has-text-white is-size-5">出場エントリー</h1>
          <div className="notification is-size-7 p-3">
            <p className="is-size-7">全国の大学で2020年度に開催されたミスターコンテストにてグランプリ・準グランプリを受賞された方は、本エントリフォームより、エントリー可能です。</p>
          </div>
          <form className="formrun" action="https://form.run/api/v1/r/ys8jyp78s8womavgbj2bf12f" method="post">
            {/*↓You can add/change fields.*/}
            <div className="field">
              <label className="label">
                名前 <span className="tag is-danger">必須</span>
              </label>
              <div className="control">
                <input
                    className="input"
                    name="名前"
                    type="text"
                    data-formrun-class-if-success="is-success"
                    data-formrun-class-if-error="is-danger"
                    data-formrun-required
                />
              </div>
              <p className="help is-danger" data-formrun-show-if-error="名前">
                名前を入力してください
              </p>
            </div>

            <div className="field">
              <label className="label">
                大学名 <span className="tag is-danger">必須</span>
              </label>
              <div className="control">
                <input
                    className="input"
                    name="大学名"
                    type="text"
                    data-formrun-class-if-success="is-success"
                    data-formrun-class-if-error="is-danger"
                    data-formrun-required
                />
              </div>
              <p className="help is-danger" data-formrun-show-if-error="大学名">
                大学名を入力してください
              </p>
            </div>

            <div className="field">
              <label className="label">
                出場コンテスト名 <span className="tag is-danger">必須</span>
              </label>
              <div className="control">
                <input
                    className="input"
                    name="出場コンテスト名"
                    type="text"
                    data-formrun-class-if-success="is-success"
                    data-formrun-class-if-error="is-danger"
                    data-formrun-required
                />
              </div>
              <p className="help is-danger" data-formrun-show-if-error="出場コンテスト名">
                出場コンテスト名を入力してください
              </p>
            </div>

            <div className="field">
              <label className="label">
                電話番号 <span className="tag is-danger">必須</span>
              </label>
              <div className="control">
                <input
                    className="input"
                    name="電話番号"
                    type="tel"
                    data-formrun-class-if-success="is-success"
                    data-formrun-class-if-error="is-danger"
                    data-formrun-type="tel"
                    data-formrun-required
                />
              </div>
              <p className="help is-danger" data-formrun-show-if-error="電話番号">
                電話番号を正しく入力してください
              </p>
            </div>

            <div className="field">
              <label className="label">
                メールアドレス <span className="tag is-danger">必須</span>
              </label>
              <div className="control">
                <input
                    className="input"
                    name="メールアドレス"
                    type="email"
                    data-formrun-type="email"
                    data-formrun-required
                />
              </div>
              <p className="help is-danger" data-formrun-show-if-error="メールアドレス">
                メールアドレスを正しく入力してください
              </p>
            </div>

            <div className="field">
              <label className="label">
                コンテスト受賞歴 <span className="tag is-danger">必須</span>
              </label>
              <div className="control">
                <div className="field has-text-white">
                  <input
                      className="is-checkradio is-white is-small"
                      id="firstPrize"
                      type="radio"
                      name="受賞"
                      value="グランプリ"
                      data-formrun-required
                  />
                  <label htmlFor="firstPrize" className="has-text-weight-bold">
                    グランプリ
                  </label>
                  <input
                      className="is-checkradio is-white is-small"
                      id="secondPrize"
                      type="radio"
                      name="受賞"
                      value="準グランプリ"
                      data-formrun-required
                  />
                  <label htmlFor="secondPrize" className="has-text-weight-bold">
                    準グランプリ
                  </label>
                </div>
              </div>
              <p className="help is-danger" data-formrun-show-if-error="受賞">
                コンテスト受賞歴を選択してください
              </p>
            </div>

            <div className="field">
              <label className="label">
                意気込み <span className="tag is-danger">必須</span>
              </label>
              <div className="control">
                <textarea
                    className="textarea"
                    name="意気込み"
                    data-formrun-class-if-success="is-success"
                    data-formrun-class-if-error="is-danger"
                    data-formrun-required
                />
              </div>
              <p className="help is-danger" data-formrun-show-if-error="意気込み">
                意気込みを入力してください
              </p>
            </div>

            {/* ボット投稿をブロックするためのタグ */}
            <div className="_formrun_gotcha">
              <style media="screen">{`._formrun_gotcha {position:absolute!important;height:1px;width:1px;overflow:hidden;}`}</style>
              <label htmlFor="_formrun_gotcha">If you are a human, ignore this field</label>
              <input type="text" name="_formrun_gotcha" id="_formrun_gotcha" tabIndex={-1} />
            </div>


            <div className="field pt-3">
              <div className="control">
                <button
                    className="button is-submit is-medium is-fullwidth"
                    type="submit"
                    data-formrun-error-text="未入力の項目があります"
                    data-formrun-submitting-text="送信中..."
                >
                  送信
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <footer className="footer has-background-black is-padding-bottom-3">
        <div className="content has-text-centered">
          <Logo width={150} />
          <p className="is-size-7 mt-3">Copyright © 2020 株式会社エイジ・エンタテインメント All Rights Reserved.</p>
        </div>
      </footer>
    </div>
  );
};
